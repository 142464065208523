@import '../theme-bootstrap';

@font-face {
  font-family: 'OptimaDisplayBook';
  src: url('#{$netstorage-font-path}OptimaDisplay/optimadisplay-book-webfont.woff2') format('woff2');
  font-display: swap;
  font-weight: normal;
  font-style: normal;
}

$discover-max-width: 1144px;
$max-mob-width: 767px;
$min-pc-width: 768px;
$discover-nav-border: #d5d5d5;
$optima-reg: 'Optima Regular';
$optima-book: 'OptimaDisplayBook';
$filter-arrow: url(/media/export/cms/discover/icons/filter_arrow.svg) right no-repeat;
$filter-arrow-mob: url(/media/export/cms/discover/icons/filter_arrow_mob.svg) right no-repeat;
$vid-play-white: url(/media/export/cms/discover/icons/vid_play_white.svg);
$vid-play-navy: url(/media/export/cms/discover/icons/vid_play_navy.svg);
$vid-play-black: url(/media/export/cms/discover/icons/vid_play_black.svg);
$youtube-close: url(/media/boutiques/common/video_close_x_white.svg);
$custom-scrollbar-thumb: #ababab;
$custom-scrollbar-track: #e2e2e2;

.discover-landing {
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
  transition-delay: 1s;
  &.showing {
    opacity: 1;
  }
}

// Discover LP Wrapper & Discover More
.discover-landing,
.discover-more__module {
  margin: auto;
  width: 100%;
  max-width: $discover-max-width;
  position: relative;
  overflow: hidden;
  font-size: 100%;
  cursor: default;
  h1 {
    font-size: 38px;
    letter-spacing: 0.145em;
    width: 100%;
    text-align: center;
    color: $color-navy;
    padding: 4.6% 0 1.4% 0;
    @media screen and (max-width: $max-mob-width) {
      font-size: 6.875vw;
      padding: 6.2% 0 3% 0;
      letter-spacing: 0.175em;
    }
    a {
      cursor: pointer;
    }
    .isSafari & {
      font-family: $optima-reg;
    }
  }
  .discover-more__cta {
    display: inline-block;
    width: 100%;
    text-align: center;
    margin-top: 0.55%;
  }

  // Navigation Menu.
  .discover-navigation {
    margin-bottom: 1.312335vw;
    border-top: 1px solid $discover-nav-border;
    border-bottom: 1px solid $discover-nav-border;
    @media screen and (max-width: $max-mob-width) {
      margin-bottom: 3.125vw;
    }
    @media screen and (min-width: $discover-max-width) {
      margin-bottom: 15px;
    }
    &__menu {
      width: 100%;
      text-align: center;
      overflow-x: auto;
      white-space: nowrap;
      &::-webkit-scrollbar {
        display: none;
      }
      li.discover-navigation__step {
        display: inline-block;
        position: relative;
        font-size: 12px;
        letter-spacing: 0.14em;
        line-height: 4;
        font-family: $akzidenz;
        color: $color-navy;
        font-weight: 700;
        margin: 0 23px;
        padding-top: 1px;
        text-transform: uppercase;
        @media screen and (max-width: $max-mob-width) {
          font-size: 2.5vw;
          line-height: 4.3;
          margin: 0 3.75vw;
        }
        span,
        a {
          cursor: pointer;
          position: relative;
          @media screen and (min-width: $min-pc-width) {
            &:hover::after {
              content: '';
              position: absolute;
              width: 100%;
              #{$ldirection}: 0;
              bottom: 1px;
              border-bottom: 1px solid $color-navy;
            }
          }
        }
      }
    }
  }

  // Discover Blocks.
  .discover-block {
    position: relative;
    display: inline-block;
    width: 100%;
    .discover-block__container {
      overflow: hidden;
      .discover-block__url {
        cursor: pointer;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 2;
        top: 0;
        #{$ldirection}: 0;
      }
      .discover-block__vid--inline {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        display: block;
        background-color: $color-black;
        object-fit: cover;
      }
      .discover-block__img {
        width: 100%;
        position: relative;
        overflow: hidden;
        img.discover-block__olapic--icon {
          display: none;
        }
        .discover-block__olapic--content {
          display: none;
        }
        .discover-block__img--full {
          display: none;
        }
        img {
          width: 100%;
          display: block;
        }
      }
      .discover-block__subtags {
        position: relative;
        color: $color-navy;
        width: 100%;
        margin: 0 auto;
        html[dir='rtl'] & {
          text-align: #{$rdirection};
        }
        li {
          display: inline-block;
          font-size: 12px;
          letter-spacing: 0.14em;
          line-height: 1.8;
          font-family: $akzidenz;
          text-transform: uppercase;
          margin: 0;
          @media screen and (max-width: $max-mob-width) {
            font-size: 2.5vw;
            letter-spacing: 0.15em;
            line-height: 1.65;
          }
          @media screen and (max-width: 480px) {
            font-size: 12px;
          }
          &:not(:last-child)::after {
            content: ', ';
            visibility: visible;
          }
          span {
            position: relative;
            cursor: pointer;
            a {
              color: $color-white;
              border-bottom: 1px solid $color-white;
            }
          }
        }
      }
      .discover-block__subtags,
      .discover-block__button {
        z-index: 3;
      }
      .discover-block__copy {
        width: 100%;
        margin: 0 auto;
        z-index: 1;
        text-align: center;
        color: $color-navy;
        .discover-block__header {
          font-family: $optima-book;
          font-size: 1.924758vw;
          line-height: 1.15;
          letter-spacing: 0.05em;
          @media screen and (max-width: $max-mob-width) {
            letter-spacing: 0;
            line-height: 1.1;
            font-size: 4.375vw;
          }
          @media screen and (min-width: $discover-max-width) {
            font-size: 22px;
          }
          &:empty {
            display: none;
          }
        }
        .discover-block__teaser {
          font-size: 1.312335vw;
          letter-spacing: 0.05em;
          line-height: 1.5;
          font-family: $akzidenz;
          @media screen and (max-width: 915px) {
            font-size: 12px;
          }
          @media screen and (max-width: $max-mob-width) {
            font-size: 3.125vw;
            letter-spacing: 0.02em;
          }
          @media screen and (max-width: 427px) {
            font-size: 12px;
          }
          @media screen and (min-width: $discover-max-width) {
            font-size: 15px;
          }
          &:empty {
            display: none;
          }
        }
        .discover-block__header p,
        .discover-block__teaser p {
          font: inherit;
          -webkit-margin-before: 0;
          -webkit-margin-after: 0;
          letter-spacing: inherit;
          padding: 0;
          margin: 0;
        }
        .discover-block__button {
          display: none;
        }
      }

      // If a container is empty.
      &:empty {
        margin: 0;
        height: 0;
        * {
          display: none;
        }
      }
      &.olapic_tile {
        overflow: visible;
        .discover-block__img {
          overflow: visible;
          img.discover-block__olapic--icon {
            display: block;
            position: absolute;
            bottom: 3%;
            #{$rdirection}: 3%;
            width: 40px !important;
            height: 40px !important;
          }
          .discover-block__olapic--content {
            display: block;
            display: -webkit-box;
            position: absolute;
            width: 100%;
            height: 50px;
            bottom: -50px;
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: 1.6;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            @media screen and (max-width: $max-mob-width) {
              padding: 0 3%;
            }
          }
          .olapicimg {
            background-size: 100%;
            cursor: pointer;
          }
        }
        .discover-block__copy {
          display: none;
        }
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5) {
          .discover-block__subtags {
            margin-top: 50px !important;
            @media screen and (max-width: $max-mob-width) {
              margin-top: 50px !important;
            }
          }
        }
      }
    }

    // BLOCK A LAYOUT
    &.discover-block__a {
      margin: 1.049868vw 0 0.699912vw 0;
      @media screen and (max-width: $max-mob-width) {
        margin: 2.8125vw 0;
      }
      @media screen and (min-width: $discover-max-width) {
        margin: 12px 0 8px 0;
      }
      .discover-block__container {
        // Large Vertical.
        &:nth-child(1) {
          float: #{$ldirection};
          .discover-block__img {
            picture {
              &.discover-block__img {
                &--rect,
                &--sq {
                  display: none;
                }
              }
            }
            img {
              height: 59.842476vw;
              @media screen and (max-width: $max-mob-width) {
                height: auto;
                &.discover-block__img-empty {
                  height: 122.8125vw;
                }
              }
              @media screen and (min-width: $discover-max-width) {
                height: 684px;
              }
            }
          }
          .discover-block__copy {
            @media screen and (max-width: $max-mob-width) {
              bottom: 2.9%;
              padding-#{$ldirection}: 15px;
              padding-#{$rdirection}: 15px;
            }
            .discover-block__header {
              font-family: $optima-book;
              font-size: 3.149604vw;
              line-height: 1.15;
              letter-spacing: 0.03em;
              margin-bottom: 0.9%;
              @media screen and (max-width: $max-mob-width) {
                font-size: 6.25vw;
                letter-spacing: 0.055em;
              }
              @media screen and (min-width: $discover-max-width) {
                font-size: 36px;
              }
            }
            .discover-block__teaser {
              letter-spacing: 0.03em;
              @media screen and (max-width: $max-mob-width) {
                letter-spacing: 0;
              }
            }
          }
          .discover-block__subtags {
            top: 1.9%;
            @media screen and (max-width: $max-mob-width) {
              top: 2.9%;
              padding-#{$ldirection}: 15px;
              padding-#{$rdirection}: 15px;
            }
          }
        }

        // Rectangle.
        &:nth-child(2) {
          margin-bottom: 2.62467vw;
          float: #{$rdirection};
          @media screen and (min-width: $discover-max-width) {
            margin-bottom: 30px;
          }
          .discover-block__img {
            picture {
              &.discover-block__img {
                &--lg,
                &--sq {
                  display: none;
                }
              }
            }
            img {
              height: 27.296568vw;
              @media screen and (max-width: $max-mob-width) {
                height: auto;
                &.discover-block__img-empty {
                  height: 55.9375vw;
                }
              }
              @media screen and (min-width: $discover-max-width) {
                height: 312px;
              }
            }
          }
          .discover-block__copy {
            @media screen and (max-width: $max-mob-width) {
              bottom: 3.2%;
              padding-#{$ldirection}: 15px;
              padding-#{$rdirection}: 15px;
            }
            .discover-block__header {
              margin-bottom: 1.4%;
              @media screen and (max-width: $max-mob-width) {
                font-size: 6.25vw;
                letter-spacing: 0.055em;
                margin-bottom: 1.9%;
              }
            }
            .discover-block__teaser {
              display: none;
            }
          }
          .discover-block__subtags {
            top: 4.4%;
            @media screen and (max-width: $max-mob-width) {
              top: 6.4%;
              padding-#{$ldirection}: 15px;
              padding-#{$rdirection}: 15px;
            }
          }
        }

        // Large Vertical & Rectangle.
        &:nth-child(1),
        &:nth-child(2) {
          position: relative;
          width: 48.731373vw;
          @media screen and (max-width: $max-mob-width) {
            float: none;
            width: 100%;
            margin-bottom: 6.25vw;
          }
          @media screen and (min-width: $discover-max-width) {
            width: 557px;
          }
          .discover-block__url {
            &-usegradient {
              background: linear-gradient(
                to bottom,
                $color-navy -30%,
                transparent 21%,
                transparent 70%,
                $color-navy 125%
              );
            }
          }
          .discover-block__subtags {
            position: absolute;
            color: $color-white;
            text-align: center;
          }
          .discover-block__copy {
            z-index: 10;
            position: absolute;
            color: $color-white;
            @media screen and (min-width: $min-pc-width) {
              bottom: 2%;
            }
          }
        }

        // Small Square LHS & RHS.
        &:nth-child(3),
        &:nth-child(4) {
          position: relative;
          width: 23.097096vw;
          @media screen and (max-width: $max-mob-width) {
            width: 47.657%;
          }
          @media screen and (min-width: $discover-max-width) {
            width: 264px;
          }

          // Need to be able to override CMS set copy color for certain blocks - hence the !important instances below.
          // Text copy rules also differ in tiles between Desktop and Mobile.
          .discover-block__url {
            background: none !important; // Always override the CMS colouring.
          }
          .discover-block__copy {
            text-align: #{$ldirection};
            margin-top: 2.4%;
            @media screen and (max-width: $max-mob-width) {
              margin-top: 5.1%;
              padding: 0 3%;
            }
            &-defaultcolor {
              color: $color-navy !important; // Always override the CMS colouring.
              .discover-block__header {
                color: $color-navy !important; // Always override the CMS colouring.
              }
              .discover-block__teaser {
                color: $color-navy !important; // Always override the CMS colouring.
              }
            }
            html[dir='rtl'] & {
              text-align: #{$rdirection};
            }
            .discover-block__header {
              margin-bottom: 4.8%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              @media screen and (max-width: $max-mob-width) {
                margin-bottom: 5.9%;
                letter-spacing: 0.04em;
              }
            }
            .discover-block__teaser {
              margin: -1% 0 4% 0;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: normal;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              height: auto;
              display: -webkit-box;
              @media screen and (max-width: $max-mob-width) {
                margin: -1% 0 5.1% 0;
                font-size: 3.125vw;
                line-height: 1.2;
                letter-spacing: 0.02em;
                height: auto;
              }
            }
          }
          .discover-block__subtags {
            margin-top: -0.5%;
            @media screen and (max-width: $max-mob-width) {
              padding: 0 3%;
              margin-top: 0;
            }
            li {
              span {
                a {
                  color: $color-navy;
                  border-bottom: 1px solid $color-navy;
                }
              }
            }
            &-defaultcolor {
              li {
                span {
                  a {
                    color: $color-navy !important; // Always override the CMS colouring.
                    border-bottom: 1px solid $color-navy !important; // Always override the CMS colouring.
                  }
                }
              }
            }
          }
          .discover-block__img {
            picture {
              &.discover-block__img {
                &--lg,
                &--rect {
                  display: none;
                }
              }
            }
            img,
            .olapicimg {
              height: 23.009607vw;
              @media screen and (max-width: $max-mob-width) {
                height: auto;
                &.discover-block__img-empty {
                  height: 47.65625vw;
                }
              }
              @media screen and (min-width: $discover-max-width) {
                height: 263px;
                width: 263px;
              }
            }
          }
          .ytPlayer__icon,
          .video_content_v1 .video_content_cta_outer {
            margin-#{$ldirection}: -9.5%;
          }
        }

        // Small Square LHS.
        &:nth-child(3) {
          float: #{$ldirection};
          margin-#{$ldirection}: 2.537181vw;
          @media screen and (max-width: $max-mob-width) {
            margin-#{$ldirection}: 0;
          }
          @media screen and (min-width: $discover-max-width) {
            margin-#{$ldirection}: 30px;
          }
        }

        // Small Square RHS.
        &:nth-child(4) {
          float: #{$rdirection};
        }

        // Content greater than the specified number of four block tiles.
        &:nth-child(4) ~ * {
          display: none;
        }
      }
    }

    // BLOCK B LAYOUT
    &.discover-block__b {
      text-align: #{$rdirection};
      margin: 1.137357vw 0;
      @media screen and (max-width: $max-mob-width) {
        margin: 2.8125vw 0;
      }
      @media screen and (min-width: $discover-max-width) {
        margin: 13px 0;
      }
      html[dir='rtl'] & {
        direction: ltr;
      }
      .discover-block__container {
        display: inline-block;
        vertical-align: top;
        margin-bottom: 2.274714vw;
        text-align: #{$ldirection};
        @media screen and (max-width: $max-mob-width) {
          margin-bottom: 6.25vw;
        }
        @media screen and (min-width: $discover-max-width) {
          margin-bottom: 26px;
        }
        html[dir='rtl'] & {
          direction: rtl;
        }

        // If there's only 2/6 articles available.
        &:first-child:nth-last-child(2) ~ .discover-block__container:last-child {
          float: #{$ldirection};
          margin: 0 2.62467vw;
          @media screen and (max-width: $max-mob-width) {
            margin: 0 0 6.25vw 0;
          }
          @media screen and (min-width: $discover-max-width) {
            margin: 0 30px;
          }
        }

        // If there's only 4/6 articles available.
        &:first-child:nth-last-child(4) ~ .discover-block__container:last-child {
          float: none;
          display: block;
          margin: 2.62467vw 0 0 0;
          @media screen and (max-width: $max-mob-width) {
            width: 100%;
            margin: 6.25vw 0 0 0;
          }
          @media screen and (min-width: $discover-max-width) {
            margin: 30px 0 0 0;
          }
        }

        // If there's only 5/6 articles available.
        &:first-child:nth-last-child(5) ~ .discover-block__container:nth-last-child(-n + 2) {
          @media screen and (min-width: $discover-max-width) {
            margin: 0 30px 0 0;
          }
          float: #{$ldirection};
          margin: 0 2.62467vw 0 0;
        }
        &:first-child:nth-last-child(5) ~ .discover-block__container:nth-last-child(2) {
          @media screen and (max-width: $max-mob-width) {
            float: none;
            margin: 4.375vw 0;
          }
        }
        &:first-child:nth-last-child(5) ~ .discover-block__container:last-child {
          @media screen and (max-width: $max-mob-width) {
            margin: 1.875vw 0 0 0;
          }
        }
        &:nth-child(1) {
          .discover-block__copy {
            @media screen and (max-width: $max-mob-width) {
              padding-#{$ldirection}: 15px;
              padding-#{$rdirection}: 15px;
            }
          }
          .discover-block__subtags {
            @media screen and (max-width: $max-mob-width) {
              padding-#{$ldirection}: 15px;
              padding-#{$rdirection}: 15px;
            }
          }
        }

        // Rectangles.
        &:nth-child(1),
        &:nth-child(6) {
          position: relative;
          width: 48.731373vw;
          @media screen and (max-width: $max-mob-width) {
            width: 100%;
          }
          @media screen and (min-width: $discover-max-width) {
            width: 557px;
          }
          .discover-block__img {
            picture {
              &.discover-block__img {
                &--lg,
                &--sq {
                  display: none;
                }
              }
            }
            img {
              height: 27.296568vw;
              @media screen and (max-width: $max-mob-width) {
                height: auto;
              }
              @media screen and (min-width: $discover-max-width) {
                height: 312px;
              }
            }
          }
          .discover-block__subtags {
            position: absolute;
            top: 4.4%;
            color: $color-white;
            text-align: center;
            li {
              span {
                &::after {
                  border-bottom: 1px solid $color-white;
                }
              }
            }
          }
          .discover-block__copy {
            position: absolute;
            bottom: 2%;
            color: $color-white;
            .discover-block__header {
              margin-bottom: 1.4%;
            }
            .discover-block__teaser {
              display: none;
            }
          }
        }

        // Small Squares.
        // LHS Square on Rows One & Three.
        // RHS Square on Rows Two & Four.
        &:nth-child(2),
        &:nth-child(5) {
          margin: 0 2.099736vw 2.274714vw 0;
          @media screen and (max-width: $max-mob-width) {
            float: #{$ldirection};
            margin: 0 0 6.25vw;
          }
          @media screen and (min-width: $discover-max-width) {
            margin: 0 24px 26px 0;
          }
        }

        // All Small Squares.
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5) {
          position: relative;
          width: 23.097096vw;
          @media screen and (max-width: $max-mob-width) {
            width: 47.657%;
          }
          @media screen and (min-width: $discover-max-width) {
            width: 264px;
          }
          .discover-block__copy {
            text-align: #{$ldirection};
            margin-top: 2.4%;
            html[dir='rtl'] & {
              text-align: #{$rdirection};
            }
            .discover-block__header {
              margin-bottom: 4.8%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .discover-block__teaser {
              margin: -1% 0 4%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: normal;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              height: auto;
              display: -webkit-box;
            }
          }
          .discover-block__subtags {
            margin-top: -0.5%;
            @media screen and (max-width: $max-mob-width) {
              padding: 0 3%;
              margin-top: 0;
            }
            li {
              span {
                a {
                  color: $color-navy;
                  border-bottom: 1px solid $color-navy;
                }
              }
            }
          }
          .discover-block__img {
            picture {
              &.discover-block__img {
                &--lg,
                &--rect {
                  display: none;
                }
              }
            }
            img,
            .olapicimg {
              height: 23.009607vw;
              @media screen and (max-width: $max-mob-width) {
                height: auto;
                width: 100%;
              }
              @media screen and (min-width: $discover-max-width) {
                height: 263px;
                width: 263px;
              }
            }
          }
          .ytPlayer__icon,
          .video_content_v1 .video_content_cta_outer {
            margin-#{$ldirection}: -9.5%;
          }
        }
        &:nth-child(4) {
          @media screen and (max-width: $max-mob-width) {
            .discover-block__copy {
              padding-#{$ldirection}: 15px;
              padding-#{$rdirection}: 15px;
              html[dir='rtl'] & {
                text-align: center;
              }
              .discover-block__header {
                overflow: visible;
                white-space: normal;
              }
              .discover-block__teaser {
                overflow: visible;
                white-space: normal;
                height: auto;
              }
            }
            .discover-block__subtags {
              padding-#{$ldirection}: 15px;
              padding-#{$rdirection}: 15px;
              li {
                span {
                  a {
                    color: $color-white;
                    border-bottom: 1px solid $color-white;
                  }
                }
              }
            }
          }
        }

        // Content greater than six blocks.
        &:nth-child(6) ~ * {
          display: none;
        }

        // RHS Small Square (mobile).
        &:nth-child(6) {
          @media screen and (max-width: $max-mob-width) {
            .discover-block__copy,
            .discover-block__subtags {
              html[dir='rtl'] & {
                text-align: #{$rdirection};
              }
            }
            .discover-block__copy {
              position: inherit;
              bottom: 0;
              color: $color-navy;
              text-align: inherit;
              margin-top: 2.4%;
              .discover-block__header {
                overflow: hidden;
                white-space: $discover-nav-border;
                text-overflow: ellipsis;
              }
              .discover-block__teaser {
                display: block; // IE fallback.
                overflow: hidden;
                white-space: normal;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                height: auto;
                display: -webkit-box;
              }
            }
            .discover-block__vid--inline {
              transform: scale(1);
            }
            .discover-block__subtags {
              position: inherit;
              top: 0;
              color: $color-navy;
              text-align: inherit;
              li {
                span {
                  &::after {
                    border-bottom: 1px solid $color-navy;
                  }
                  a {
                    color: $color-navy;
                    border-bottom: 1px solid $color-navy;
                  }
                }
              }
            }
            .ytPlayer__icon,
            .video_content_v1 .video_content_cta_outer {
              margin-#{$ldirection}: -9.5%;
            }
          }
        }

        // Small Squares LHS & RHS (mobile).
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(5),
        &:nth-child(6) {
          @media screen and (max-width: $max-mob-width) {
            width: 47.657%;
            margin-bottom: 5.625vw;
            .discover-block__img {
              picture {
                &.discover-block__img {
                  &--lg,
                  &--rect {
                    display: none;
                  }
                  &--sq {
                    display: block;
                    width: 100%;
                  }
                }
              }
              img {
                &.discover-block__img-empty {
                  height: 47.65625vw;
                }
              }
            }
            .discover-block__copy {
              margin-top: 5.1%;
              padding: 0 3%;
              .discover-block__header {
                margin-bottom: 5.9%;
                letter-spacing: 0.04em;
              }
              .discover-block__teaser {
                margin: -1% 0 5.1% 0;
                font-size: 3.125vw;
                line-height: 1.2;
                letter-spacing: 0.02em;
              }
            }
            .discover-block__subtags {
              padding: 0 3%;
            }
          }
        }

        // When there's < 6 blocks.
        &:nth-last-child(-n + 3) {
          margin-bottom: 0;
        }

        // All Rectangle Blocks (mobile).
        &:nth-child(1),
        &:nth-child(4) {
          float: #{$ldirection};
          @media screen and (max-width: $max-mob-width) {
            float: none;
            width: 100%;
            margin-bottom: 6.25vw;
            display: block;
            .discover-block__subtags {
              position: absolute;
              top: 6.4%;
              color: $color-white;
              text-align: center;
              li {
                span {
                  &::after {
                    border-bottom: 1px solid $color-white;
                  }
                }
              }
            }
            .discover-block__copy {
              position: absolute;
              bottom: 3.2%;
              color: $color-white;
              text-align: center;
              margin-top: 0;
              .discover-block__header {
                @media screen and (max-width: $max-mob-width) {
                  font-size: 6.25vw;
                  margin-bottom: 2.1%;
                  letter-spacing: 0.055em;
                }
              }
              .discover-block__teaser {
                display: none;
              }
            }
            .discover-block__img {
              picture {
                &.discover-block__img {
                  &--lg,
                  &--sq {
                    display: none;
                  }
                  &--rect {
                    display: block;
                    width: 100%;
                  }
                }
              }
              img {
                &.discover-block__img-empty {
                  height: 55.9375vw;
                }
              }
              .ytPlayer__icon,
              .video_content_v1 .video_content_cta_outer {
                @media screen and (max-width: $max-mob-width) {
                  margin-#{$ldirection}: -4.5%;
                }
              }
            }
          }
        }
      }

      // BLOCK B MIDDLE LAYOUT
      &--middle {
        display: block;
        width: 100%;
        .discover-block__container {
          margin: 1.137357vw 0 1.487313vw;
          @media screen and (max-width: $max-mob-width) {
            margin: 2.8125vw 0 3.4375vw;
          }
          @media screen and (min-width: $discover-max-width) {
            margin: 13px 0 17px;
          }
          .discover-block__vid {
            &.discover-block__img-desktop--empty.discover-block__img-mob--empty {
              .discover-block__vid--inline {
                position: relative;
              }
            }
            &.discover-block__img-mob--empty {
              .discover-block__vid--inline {
                position: absolute;
              }
            }
            &.discover-block__img-desktop--empty {
              .discover-block__vid--inline {
                position: relative;
                @media screen and (max-width: $max-mob-width) {
                  position: absolute;
                }
              }
            }
          }
          .discover-block__img {
            picture {
              &.discover-block__img {
                &--full {
                  display: block;
                  &.discover-block__img-desktop--empty.discover-block__img-mob--empty {
                    display: none;
                  }
                  &.discover-block__img-desktop--empty {
                    @media screen and (max-width: $max-mob-width) {
                      display: block;
                    }
                    display: none;
                  }
                }
                &--lg,
                &--rect,
                &--sq {
                  display: none;
                }
              }
            }
            img {
              height: auto;
            }
            .ytPlayer__icon,
            .video_content_v1 .video_content_cta_outer {
              margin-#{$ldirection}: -2.2%;
              @media screen and (max-width: $max-mob-width) {
                margin-#{$ldirection}: -4.5%;
              }
            }
          }
          .discover-block__copy {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            color: $color-white;
            z-index: 2;
            @media screen and (max-width: $max-mob-width) {
              bottom: 3.2%;
              top: auto;
              transform: translateY(0);
              padding-#{$ldirection}: 15px;
              padding-#{$rdirection}: 15px;
            }
            .discover-block__header {
              font-family: $optima-book;
              font-size: 3.149604vw;
              line-height: 1.15;
              letter-spacing: 0.056em;
              margin-bottom: 0.5%;
              @media screen and (max-width: $max-mob-width) {
                font-size: 6.25vw;
                margin-bottom: 5.2%;
                letter-spacing: 0.055em;
              }
              @media screen and (min-width: $discover-max-width) {
                font-size: 36px;
              }
            }
            .discover-block__teaser {
              margin-bottom: 0;
              @media screen and (max-width: $max-mob-width) {
                display: none;
              }
            }
            &.discover-block__copy--below {
              @media screen and (max-width: $max-mob-width) {
                position: relative;
                color: $color-navy;
                margin-top: 2.2%;
                .discover-block__teaser {
                  display: block;
                }
                .discover-block__button {
                  margin-top: 3.5vw;
                  &.button--inverted,
                  &.button--light {
                    color: $color-navy;
                    border: 1px solid $color-navy;
                  }
                }
              }
            }
            &.default {
              .discover-block__button {
                @media screen and (max-width: $max-mob-width) {
                  display: none;
                }
              }
            }
            .discover-block__button {
              display: inline-block;
              margin-top: 1.7%;
            }
          }
          .discover-block__subtags {
            position: absolute;
            top: 2.1%;
            color: $color-white;
            text-align: center;
            @media screen and (max-width: $max-mob-width) {
              top: 6.5%;
              padding-#{$ldirection}: 15px;
              padding-#{$rdirection}: 15px;
            }
            li {
              span {
                &::after {
                  border-bottom: 1px solid $color-white;
                }
              }
            }
          }

          // Content greater than the specified number a single tile.
          &:nth-child(1) ~ * {
            display: none;
          }
        }
        &:empty {
          margin: 0;
          height: 0;
          * {
            display: none;
          }
        }
      }
    }

    // BLOCK C LAYOUT
    &.discover-block__c {
      margin-bottom: 1.137357vw 0 0.699912vw;
      @media screen and (max-width: $max-mob-width) {
        margin: 2.8125vw 0 3.4375vw 0;
      }
      @media screen and (min-width: $discover-max-width) {
        margin: 13px 0 8px 0;
      }
      .discover-block__container {
        // Rectangle & Large Vertical.
        &:nth-child(1),
        &:nth-child(2) {
          position: relative;
          width: 48.731373vw;
          @media screen and (min-width: $discover-max-width) {
            width: 557px;
          }
          .discover-block__subtags {
            position: absolute;
            top: 2.1%;
            color: $color-white;
            text-align: center;
            li {
              span {
                &::after {
                  border-bottom: 1px solid $color-white;
                }
              }
            }
          }
          .discover-block__copy {
            position: absolute;
            bottom: 2%;
            color: $color-white;
          }
        }

        // Rectangle.
        &:nth-child(1) {
          margin-bottom: 2.62467vw;
          float: #{$ldirection};
          @media screen and (max-width: $max-mob-width) {
            float: none;
            width: 100%;
            margin-bottom: 6.25vw;
          }
          @media screen and (min-width: $discover-max-width) {
            margin-bottom: 30px;
          }
          .discover-block__img {
            picture {
              &.discover-block__img {
                &--lg,
                &--sq {
                  display: none;
                }
              }
            }
            img {
              @media screen and (max-width: $max-mob-width) {
                height: auto;
                &.discover-block__img-empty {
                  height: 55.9375vw;
                }
              }
              @media screen and (min-width: $discover-max-width) {
                height: 312px;
              }
              height: 27.296568vw;
            }
          }
          .discover-block__subtags {
            @media screen and (max-width: $max-mob-width) {
              padding-#{$ldirection}: 15px;
              padding-#{$rdirection}: 15px;
              top: 6.4%;
            }
          }
          .discover-block__copy {
            @media screen and (max-width: $max-mob-width) {
              bottom: 3.2%;
              padding-#{$ldirection}: 15px;
              padding-#{$rdirection}: 15px;
            }
            .discover-block__header {
              margin-bottom: 1.4%;
              @media screen and (max-width: $max-mob-width) {
                font-size: 6.25vw;
                letter-spacing: 0.055em;
                margin-bottom: 1.9%;
              }
            }
            .discover-block__teaser {
              display: none;
            }
          }
        }

        // Large Vertical.
        &:nth-child(2) {
          float: #{$rdirection};
          @media screen and (max-width: $max-mob-width) {
            float: #{$ldirection};
            width: 47.657%;
            margin-bottom: 6.25vw;
          }
          .discover-block__img {
            picture {
              &.discover-block__img {
                &--rect,
                &--sq {
                  display: none;
                }
                @media screen and (max-width: $max-mob-width) {
                  &--sq {
                    display: block;
                  }
                  &--rect,
                  &--lg {
                    display: none;
                  }
                }
              }
            }
            img {
              height: 59.842476vw;
              @media screen and (min-width: $discover-max-width) {
                height: 684px;
              }
              @media screen and (max-width: $max-mob-width) {
                height: auto;
              }
            }
            .ytPlayer__icon,
            .video_content_v1 .video_content_cta_outer {
              @media screen and (max-width: $max-mob-width) {
                margin-#{$ldirection}: -9.5%;
              }
            }
          }
          .discover-block__subtags {
            @media screen and (max-width: $max-mob-width) {
              position: relative;
              top: 2.1%;
              color: $color-navy;
              text-align: #{$ldirection};
              li {
                span {
                  a {
                    color: $color-navy;
                    border-bottom: 1px solid $color-navy;
                  }
                  &::after {
                    border-bottom: 1px solid $color-navy;
                  }
                }
              }
            }
          }
          .discover-block__copy {
            @media screen and (max-width: $max-mob-width) {
              position: relative;
              text-align: #{$ldirection};
              margin-top: 2.4%;
              color: $color-navy;
            }
            .discover-block__header {
              font-family: $optima-book;
              font-size: 3.149604vw;
              line-height: 1.15;
              letter-spacing: 0.03em;
              margin-bottom: 0.9%;
              @media screen and (max-width: $max-mob-width) {
                font-size: 6.25vw;
                letter-spacing: 0.055em;
              }
              @media screen and (min-width: $discover-max-width) {
                font-size: 36px;
              }
            }
            .discover-block__teaser {
              letter-spacing: 0.03em;
            }
          }
        }

        // Small Square LHS & RHS.
        &:nth-child(3),
        &:nth-child(4) {
          position: relative;
          float: #{$ldirection};
          width: 23.097096vw;
          @media screen and (max-width: $max-mob-width) {
            width: 47.657%;
          }
          @media screen and (min-width: $discover-max-width) {
            width: 264px;
          }
          .discover-block__copy {
            text-align: #{$ldirection};
            margin-top: 2%;
            html[dir='rtl'] & {
              text-align: #{$rdirection};
            }
            .discover-block__header {
              margin-bottom: 4.8%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .discover-block__teaser {
              margin: -1% 0 4% 0;
              overflow: hidden;
              white-space: normal;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              height: auto;
              display: -webkit-box;
            }
          }
          .discover-block__subtags {
            margin-top: -0.5%;
            @media screen and (max-width: $max-mob-width) {
              margin-top: 0;
            }
          }
          .discover-block__img {
            picture {
              &.discover-block__img {
                &--lg,
                &--rect {
                  display: none;
                }
              }
            }
            img {
              height: 23.009607vw;
              @media screen and (max-width: $max-mob-width) {
                height: auto;
              }
              @media screen and (min-width: $discover-max-width) {
                height: 263px;
                width: 263px;
              }
            }
          }
          .ytPlayer__icon,
          .video_content_v1 .video_content_cta_outer {
            margin-#{$ldirection}: -9.5%;
          }
        }

        // Small Square LHS & RHS (mobile).
        &:nth-child(2),
        &:nth-child(3) {
          @media screen and (max-width: $max-mob-width) {
            .discover-block__copy {
              margin-top: 5.1%;
              padding: 0 3%;
              .discover-block__header {
                font-size: 4.375vw;
                margin-bottom: 5.9%;
                letter-spacing: 0.04em;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
              .discover-block__teaser {
                margin: -1% 0 5.1% 0;
                font-size: 3.125vw;
                line-height: 1.2;
                letter-spacing: 0.02em;
                overflow: hidden;
                white-space: normal;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                height: auto;
                display: -webkit-box;
              }
            }
            .discover-block__img {
              img {
                &.discover-block__img-empty {
                  height: 47.65625vw;
                }
              }
            }
            .discover-block__subtags {
              padding: 0 3%;
            }
          }
        }

        // Small Square LHS.
        &:nth-child(3) {
          @media screen and (max-width: $max-mob-width) {
            float: #{$rdirection};
            margin-bottom: 5.625vw;
          }
        }

        // Small Square RHS.
        &:nth-child(4) {
          float: #{$rdirection};
          margin-#{$rdirection}: 2.62467vw;
          @media screen and (max-width: $max-mob-width) {
            margin-#{$rdirection}: 0;
            float: none;
            width: 100%;
          }
          @media screen and (min-width: $discover-max-width) {
            margin-#{$rdirection}: 30px;
          }
          .discover-block__img {
            picture {
              @media screen and (max-width: $max-mob-width) {
                &.discover-block__img {
                  &--lg {
                    display: block;
                  }
                  &--sq,
                  &--rect {
                    display: none;
                  }
                }
              }
            }
            img {
              @media screen and (max-width: $max-mob-width) {
                height: auto;
                &.discover-block__img-empty {
                  height: 122.8125vw;
                }
              }
            }
            .ytPlayer__icon,
            .video_content_v1 .video_content_cta_outer {
              @media screen and (max-width: $max-mob-width) {
                margin-#{$ldirection}: -4.5%;
              }
            }
          }
          .discover-block__copy {
            @media screen and (max-width: $max-mob-width) {
              position: absolute;
              bottom: 2.9%;
              color: $color-white;
              text-align: center;
              margin-top: 0;
              padding-#{$ldirection}: 15px;
              padding-#{$rdirection}: 15px;
              .discover-block__header {
                font-size: 6.25vw;
                margin-bottom: 0.9%;
                line-height: 1.15;
                letter-spacing: 0.055em;
                overflow: visible;
                white-space: normal;
              }
              .discover-block__teaser {
                letter-spacing: 0;
                margin: 0;
                overflow: visible;
                white-space: normal;
              }
            }
          }
          .discover-block__subtags {
            @media screen and (max-width: $max-mob-width) {
              position: absolute;
              top: 2.9%;
              color: $color-white;
              text-align: center;
              margin-top: 0;
              padding-#{$ldirection}: 15px;
              padding-#{$rdirection}: 15px;
              li {
                span {
                  &::after {
                    border-bottom: 1px solid $color-white;
                  }
                }
              }
            }
          }
        }

        // Content greater than the specified number of four block tiles.
        &:nth-child(4) ~ * {
          display: none;
        }
      }
    }

    // Text color styling for block B and C.
    // Needs to be able to override CMS set copy color for certain blocks - hence the !important instances below.
    // Text copy rules also differ in tiles between Desktop and Mobile.
    // Due to the dynamic/filtering nature of the page, we cannot use classes to indicate/set rules. Have to use nth-child.
    &.discover-block__b {
      .discover-block__container {
        // Mobile and Desktop get Gradient.
        &:nth-child(1) {
          .discover-block__url {
            &-usegradient {
              background: linear-gradient(
                to bottom,
                $color-navy -30%,
                transparent 21%,
                transparent 70%,
                $color-navy 125%
              );
            }
          }
          .discover-block__copy {
            z-index: 10;
          }
        }
        // Mobile and Desktop get NONE.
        &:nth-child(2) {
          .discover-block__copy {
            &-defaultcolor {
              color: $color-navy !important; // Always override the CMS colouring.
              .discover-block__header {
                color: $color-navy !important; // Always override the CMS colouring.
              }
              .discover-block__teaser {
                color: $color-navy !important; // Always override the CMS colouring.
              }
            }
          }
          .discover-block__subtags {
            &-defaultcolor {
              li {
                span {
                  a {
                    color: $color-navy !important; // Always override the CMS colouring.
                    border-bottom: 1px solid $color-navy !important; // Always override the CMS colouring.
                  }
                }
              }
            }
          }
          .discover-block__url {
            background: none !important;
          }
        }
        // Mobile and Desktop get NONE
        &:nth-child(3) {
          .discover-block__copy {
            &-defaultcolor {
              color: $color-navy !important; // Always override the CMS colouring.
              .discover-block__header {
                color: $color-navy !important; // Always override the CMS colouring.
              }
              .discover-block__teaser {
                color: $color-navy !important; // Always override the CMS colouring.
              }
            }
          }
          .discover-block__subtags {
            &-defaultcolor {
              li {
                span {
                  a {
                    color: $color-navy !important; // Always override the CMS colouring.
                    border-bottom: 1px solid $color-navy !important; // Always override the CMS colouring.
                  }
                }
              }
            }
          }
          .discover-block__url {
            background: none !important;
          }
        }
        // Mobile gets Gradient.
        // Desktop gets None.
        &:nth-child(4) {
          @media screen and (min-width: $min-pc-width) {
            .discover-block__copy {
              &-defaultcolor {
                color: $color-navy !important; // Always override the CMS colouring.
                .discover-block__header {
                  color: $color-navy !important; // Always override the CMS colouring.
                }
                .discover-block__teaser {
                  color: $color-navy !important; // Always override the CMS colouring.
                }
              }
            }
            .discover-block__subtags {
              &-defaultcolor {
                li {
                  span {
                    a {
                      color: $color-navy !important; // Always override the CMS colouring.
                      border-bottom: 1px solid $color-navy !important; // Always override the CMS colouring.
                    }
                  }
                }
              }
            }
            .discover-block__url {
              background: none !important;
            }
          }
          @media screen and (max-width: $max-mob-width) {
            .discover-block__url {
              &-usegradient {
                background: linear-gradient(
                  to bottom,
                  $color-navy -30%,
                  transparent 21%,
                  transparent 70%,
                  $color-navy 125%
                );
              }
            }
            .discover-block__copy {
              z-index: 10;
            }
          }
        }
        // Mobile and Desktop get NONE
        &:nth-child(5) {
          .discover-block__copy {
            &-defaultcolor {
              color: $color-navy !important; // Always override the CMS colouring.
              .discover-block__header {
                color: $color-navy !important; // Always override the CMS colouring.
              }
              .discover-block__teaser {
                color: $color-navy !important; // Always override the CMS colouring.
              }
            }
          }
          .discover-block__subtags {
            &-defaultcolor {
              li {
                span {
                  a {
                    color: $color-navy !important; // Always override the CMS colouring.
                    border-bottom: 1px solid $color-navy !important; // Always override the CMS colouring.
                  }
                }
              }
            }
          }
          .discover-block__url {
            background: none !important;
          }
        }
        // Mobile gets None.
        // Desktop gets Gradient.
        &:nth-child(6) {
          @media screen and (min-width: $min-pc-width) {
            .discover-block__url {
              &-usegradient {
                background: linear-gradient(
                  to bottom,
                  $color-navy -30%,
                  transparent 21%,
                  transparent 70%,
                  $color-navy 125%
                );
              }
            }
            .discover-block__copy {
              z-index: 10;
            }
          }
          @media screen and (max-width: $max-mob-width) {
            .discover-block__copy {
              &-defaultcolor {
                color: $color-navy !important; // Always override the CMS colouring.
                .discover-block__header {
                  color: $color-navy !important; // Always override the CMS colouring.
                }
                .discover-block__teaser {
                  color: $color-navy !important; // Always override the CMS colouring.
                }
              }
            }
            .discover-block__subtags {
              &-defaultcolor {
                li {
                  span {
                    a {
                      color: $color-navy !important; // Always override the CMS colouring.
                      border-bottom: 1px solid $color-navy !important; // Always override the CMS colouring.
                    }
                  }
                }
              }
            }
            .discover-block__url {
              background: none !important;
            }
          }
        }
      }
    }
    &.discover-block__c {
      .discover-block__container {
        // Mobile and Desktop get Gradient.
        &:nth-child(1) {
          .discover-block__url {
            &-usegradient {
              background: linear-gradient(
                to bottom,
                $color-navy -30%,
                transparent 21%,
                transparent 70%,
                $color-navy 125%
              );
            }
          }
          .discover-block__copy {
            z-index: 10;
          }
        }
        // Desktop gets Gradient.
        // Mobile gets None.
        &:nth-child(2) {
          @media screen and (min-width: $min-pc-width) {
            .discover-block__url {
              &-usegradient {
                background: linear-gradient(
                  to bottom,
                  $color-navy -30%,
                  transparent 21%,
                  transparent 70%,
                  $color-navy 125%
                );
              }
            }
            .discover-block__copy {
              z-index: 10;
            }
          }
          @media screen and (max-width: $max-mob-width) {
            .discover-block__copy {
              &-defaultcolor {
                color: $color-navy !important; // Always override the CMS colouring.
                .discover-block__header {
                  color: $color-navy !important; // Always override the CMS colouring.
                }
                .discover-block__teaser {
                  color: $color-navy !important; // Always override the CMS colouring.
                }
              }
            }
            .discover-block__subtags {
              &-defaultcolor {
                li {
                  span {
                    a {
                      color: $color-navy !important; // Always override the CMS colouring.
                      border-bottom: 1px solid $color-navy !important; // Always override the CMS colouring.
                    }
                  }
                }
              }
            }
            .discover-block__url {
              background: none !important;
            }
          }
        }
        // Mobile and Desktop get None.
        &:nth-child(3) {
          .discover-block__copy {
            &-defaultcolor {
              color: $color-navy !important; // Always override the CMS colouring.
              .discover-block__header {
                color: $color-navy !important; // Always override the CMS colouring.
              }
              .discover-block__teaser {
                color: $color-navy !important; // Always override the CMS colouring.
              }
            }
          }
          .discover-block__subtags {
            &-defaultcolor {
              li {
                span {
                  a {
                    color: $color-navy !important; // Always override the CMS colouring.
                    border-bottom: 1px solid $color-navy !important; // Always override the CMS colouring.
                  }
                }
              }
            }
          }
          .discover-block__url {
            background: none !important;
          }
        }
        // Mobile gets Gradient.
        // Desktop gets None.
        &:nth-child(4) {
          @media screen and (min-width: $min-pc-width) {
            .discover-block__copy {
              &-defaultcolor {
                color: $color-navy !important; // Always override the CMS colouring.
                .discover-block__header {
                  color: $color-navy !important; // Always override the CMS colouring.
                }
                .discover-block__teaser {
                  color: $color-navy !important; // Always override the CMS colouring.
                }
              }
            }
            .discover-block__subtags {
              &-defaultcolor {
                li {
                  span {
                    a {
                      color: $color-navy !important; // Always override the CMS colouring.
                      border-bottom: 1px solid $color-navy !important; // Always override the CMS colouring.
                    }
                  }
                }
              }
            }
            .discover-block__url {
              background: none !important;
            }
          }
          @media screen and (max-width: $max-mob-width) {
            .discover-block__url {
              &-usegradient {
                background: linear-gradient(
                  to bottom,
                  $color-navy -30%,
                  transparent 21%,
                  transparent 70%,
                  $color-navy 125%
                );
              }
            }
            .discover-block__copy {
              z-index: 10;
            }
          }
        }
      }
    }
    .ytPlayer__icon,
    .video_content_v1 .video_content_cta_outer {
      margin-#{$ldirection}: -4.5%;
    }
  }
  .field-menu {
    .menu-item-container {
      .menu-container {
        display: none;
      }
    }
  }
}

// Discover More
.discover-more {
  &__module {
    opacity: 0;
    transition: opacity 0.4s ease-in-out;
    transition-delay: 1s;
    &.active {
      opacity: 1;
    }
    &.carousel {
      max-width: 1366px;
      .discover-carousel__nav {
        position: absolute;
        top: 50%;
        margin-top: -50px;
        cursor: pointer;
        text-align: center;
        height: 100px;
        width: 45px;
        background-image: url(/media/export/cms/discover/icons/discover_more_left_chev.png);
        background-repeat: no-repeat;
        background-size: auto auto;
        background-position: center center;
        z-index: 91;
        &.discover-carousel__nav--left {
          #{$ldirection}: 0;
        }
        &.discover-carousel__nav--right {
          #{$rdirection}: 0;
          transform: rotate(180deg);
        }
      }
      .discover-block__b {
        margin: 0 auto;
        display: block;
        max-width: $discover-max-width;
        width: 100vw;
        .discover-carousel__wrapper {
          position: relative;
          transition: margin-#{$ldirection} 0.3s ease-out;
          &--stalled {
            transition: none !important;
          }
          .discover-carousel__slide {
            float: #{$ldirection};
            zoom: 1;
            text-align: center;
            white-space: normal;
            vertical-align: top;
            width: 1124px;
            margin: 0 10px;
            .discover-block__container:nth-of-type(1) {
              float: #{$ldirection};
            }
            .discover-block__container:nth-of-type(2) {
              margin: 0 auto;
            }
            .discover-block__container:nth-of-type(3) {
              float: #{$rdirection};
            }
            &.responsive {
              width: 100vw;
              margin: 0;
            }
          }
          .discover-carousel__inner {
            float: #{$ldirection};
          }
        }
      }
      .discover-more__cta {
        margin-top: 15px;
        .button.cta.cta__button {
          &.button--light-navy-border {
            background: $color-white;
            color: $color-navy;
            border: 1px solid $color-navy;
          }
          &.button--light {
            background: $color-white;
            color: $color-navy;
            border: none;
          }
          &.button--inverted {
            background: 0 0;
            border: solid 1px $color-white;
            text-decoration: none;
          }
        }
      }
    }
    h1 {
      padding: 4.6% 0 1.9%;
    }

    // When on the Homepage
    .multi_use_homepage_module & {
      margin-bottom: 4.4%;
      h1 {
        padding: 6.8% 0 1.9%;
      }
    }

    // When in the Article/Below the Article
    .discover-article &,
    .discover-article ~ &,
    .blog-article ~ & {
      margin-bottom: 4.6%;
      h1 {
        font-size: 22px;
        letter-spacing: 0;
        width: 100%;
        text-align: center;
        font-family: $optimaregular;
        color: $color-navy;
        @media screen and (max-width: $max-mob-width) {
          font-size: 6.875vw;
          padding: 6.2% 0 3% 0;
          letter-spacing: 0.175em;
        }
        a {
          cursor: pointer;
        }
        .isSafari & {
          font-family: $optima-reg;
        }
      }
    }
    .discover-article & {
      h1 {
        padding: 5.35% 0 0.4%;
        @media screen and (max-width: $max-mob-width) {
          padding: 6.2% 0 3%;
        }
      }
    }
    .discover-article ~ & {
      h1 {
        padding: 4.5% 0 1.3%;
        @media screen and (max-width: $max-mob-width) {
          padding: 6.2% 0 3%;
        }
      }
    }
  }
  &__alternates {
    display: none;
  }
}

// Discover Filter
.discover-filter {
  width: 100%;
  text-align: center;
  margin: 37px 0 28px;
  @media screen and (max-width: $max-mob-width) {
    margin: 4.4vw 0 3.4375vw;
  }
  select.discover-filter__select {
    visibility: hidden;
  }
  .discover-filter {
    &__title {
      margin-bottom: 15px;
      letter-spacing: 0.135em;
      @media screen and (max-width: $max-mob-width) {
        display: none;
      }
    }
    &__sentence {
      font-family: $optimalight;
      font-size: 32px;
      letter-spacing: -0.01em;
      @media screen and (max-width: $max-mob-width) {
        font-family: $optima-book;
        font-size: 5.9375vw;
        line-height: 1.5;
      }
      p {
        display: inline-block;
        @media screen and (max-width: $max-mob-width) {
          display: block;
        }
      }
      a.selectBox.discover-filter__select {
        border: 1px solid $color-white;
        border-bottom: 1px solid $color-navy;
        position: relative;
        top: 0;
        font-family: $optimalight;
        font-size: 32px;
        letter-spacing: -0.01em;
        text-transform: inherit;
        line-height: 0.75;
        margin-#{$ldirection}: 0;
        min-width: 200px;
        @media screen and (max-width: $max-mob-width) {
          font-family: $optima-book;
          font-size: 5.9375vw;
          min-width: 60vw !important;
          max-width: 80vw;
          line-height: 90%;
          border-bottom: 2px solid $color-navy;
          height: 7.65625vw;
        }
        .selectBox-label {
          @media screen and (max-width: $max-mob-width) {
            padding-#{$ldirection}: 2.65625vw;
          }
          text-transform: capitalize;
          padding-#{$ldirection}: 0;
          opacity: 0.3;
        }
        .selectBox-arrow {
          background: $filter-arrow;
          width: 16px !important;
          height: 8px;
          border: none;
          #{$rdirection}: 0;
          cursor: pointer;
          @media screen and (max-width: $max-mob-width) {
            background: $filter-arrow-mob;
            top: 50%;
            transform: translateY(-50%);
            width: 4vw !important;
            height: 4vw;
          }
        }
      }
    }
    &__error {
      display: none;
      padding-top: 2%;
      line-height: 1.5;
      @media screen and (max-width: $max-mob-width) {
        padding-top: 4%;
        font-size: 3.7vw;
        max-width: 90vw;
        margin: 0 auto;
      }
    }
  }
  p {
    font: inherit;
    -webkit-margin-before: 0;
    -webkit-margin-after: 0;
    letter-spacing: inherit;
    padding: 0;
    margin: 0;
  }
}

.discover-block-container {
  opacity: 1;
  transition: opacity 0.4s ease-in-out;
  &.hiding {
    opacity: 0;
  }
  .discover-loading-spinner {
    background: transparent;
    position: relative;
    top: auto;
    #{$ldirection}: auto;
    display: none;
    margin-bottom: 70px;
    &.showing {
      display: block;
    }
    .spinner {
      top: auto !important; //override inline styles
      bottom: -20px;
    }
  }
}

// SelectBox for the Filter is outside of .field-content.
ul.selectBox-dropdown-menu.selectBox-options.discover-filter__select-selectBox-dropdown-menu {
  font-size: 12px;
  font-family: $akzidenz;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  margin-top: 1px;
  border: none;
  margin-#{$ldirection}: 0;
  max-height: 310px;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 100;
  @media screen and (max-width: $max-mob-width) {
    margin-top: 0.6vw;
  }
  /* FF */
  scrollbar-width: thin;
  scrollbar-color: $custom-scrollbar-thumb $custom-scrollbar-track;
  .ie & {
    scrollbar-face-color: $custom-scrollbar-thumb;
    scrollbar-track-color: $custom-scrollbar-track;
    scrollbar-arrow-color: $custom-scrollbar-track;
  }
  /* Webkit */
  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  &::-webkit-scrollbar:vertical {
    width: 3px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $custom-scrollbar-thumb;
  }
  &::-webkit-scrollbar-track {
    background-color: $custom-scrollbar-track;
  }
  &.selectBox-options-top {
    li:first-child a {
      padding-top: 3px;
    }
  }
  li:first-child a {
    padding-top: 13px;
    @media screen and (max-width: $max-mob-width) {
      display: none;
    }
  }
  li:nth-of-type(2) a {
    @media screen and (max-width: $max-mob-width) {
      padding-top: 13px;
    }
  }
  li a {
    cursor: pointer;
    @media screen and (max-width: $max-mob-width) {
      font-size: 3.7vw;
      text-transform: none;
      padding: 2.65625vw;
      line-height: 130%;
      white-space: pre-wrap;
    }
  }
  li.selectBox-selected a,
  li a:hover {
    font-weight: bold;
    background-color: $color-white;
  }
}

// Discover Landing & Article
.discover-landing,
.discover-article,
.discover-more__module {
  .ytPlayer__icon,
  .video_content_v1 .video_content_cta_outer {
    position: absolute;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    top: 50%;
    transform: translateY(-50%);
    #{$ldirection}: 50%;
    cursor: pointer;
    z-index: 3;
    &.navy,
    .video_content_cta.show.dark {
      background-image: $vid-play-navy;
    }
    &.black {
      background-image: $vid-play-black;
    }
    &.white,
    .video_content_cta.show.light {
      background-image: $vid-play-white;
    }
  }
  .ytPlayer__icon {
    background-image: $vid-play-white;
    width: 49px;
    height: 55px;
    @media screen and (max-width: $max-mob-width) {
      width: 8.75vw;
      height: 9.84375vw;
    }
    &--only {
      cursor: default;
      z-index: 1;
    }
  }
  .video_content_v1 {
    .video_content_cta_outer {
      background-image: none;
      .video_content_cta {
        width: 49px;
        height: 55px;
        display: block;
        @media screen and (max-width: $max-mob-width) {
          width: 8.75vw;
          height: 9.84375vw;
        }
      }
    }
  }
  .discover-block .discover-block__container {
    .video_content_v1 {
      .el_vcv1_share_inner {
        .el_vcv1_share__icon_links {
          img {
            height: auto;
          }
        }
      }
    }
  }
  .ytPlayer {
    .ytPlayer__img {
      background-image: $youtube-close;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      vertical-align: inherit;
      z-index: 996;
      height: 6.2%;
      top: 0;
      img {
        visibility: hidden;
      }
    }
  }
}

// Discover Article Wrapper.
.discover-article {
  margin: auto;
  width: 100%;
  max-width: $discover-max-width;
  position: relative;
  overflow: hidden;
  font-size: 100%;
  padding-top: 2.15%;
  cursor: default;
  @media screen and (max-width: $max-mob-width) {
    padding-top: 4.2%;
  }
  .discover-article__social-share--mob {
    display: none;
    visibility: hidden;
    @media screen and (max-width: $max-mob-width) {
      ul {
        margin-bottom: 14px;
      }
    }
    @media screen and (max-width: $medium-max) {
      display: block;
      visibility: visible;
    }
  }
  .discover-article__social-share--desktop {
    @media screen and (max-width: $medium-max) {
      display: none;
      visibility: hidden;
    }
  }
  .discover-article__social {
    position: absolute;
    z-index: 2;
    .discover-article__social {
      &-wrapper {
        position: relative;
        display: inline-block;
        margin: 0 auto 37px;
        .discover-article__social-container {
          position: absolute;
          #{$ldirection}: auto;
          top: 197px;
          .ie & {
            height: 150px;
          }
          .discover-article__social-share {
            position: absolute;
            margin: auto;
            top: 0;
            #{$ldirection}: 0;
            bottom: auto;
            #{$rdirection}: auto;
            li {
              position: relative;
              width: 50px;
              height: 50px;
              transition: background 200ms ease-out;
              cursor: pointer;
              vertical-align: middle;
              text-align: center;
              text-transform: uppercase;
              a {
                display: inline;
                text-transform: none;
                color: $color-navy;
                font-size: 12px;
                text-align: #{$ldirection};
                img {
                  position: relative;
                  &.lazyload,
                  &.lazyloading {
                    visibility: hidden;
                  }
                }
              }
            }
          }
        }
      }
    }
    .discover-article__social-share--mob,
    .discover-article__social--pinterest {
      display: none;
    }
  }
  .discover-article__section {
    position: relative;
    width: 100%;
    &.discover-article__pinterest {
      text-align: center;
      .basic-img__wrapper {
        position: relative;
        display: inline-block;
        max-width: 100%;
        img {
          margin-bottom: 5.5%;
        }
      }
    }
    &.discover-article__social--attach {
      @media screen and (max-width: $medium-max) {
        text-align: center;
        .basic-img__wrapper {
          display: inline-block;
        }
        .discover-article__social--pinterest {
          bottom: 13.6%;
        }
      }
      @media screen and (max-width: $max-mob-width) {
        .discover-article__social--pinterest {
          bottom: 15.6%;
        }
      }
      .discover-article__social-share--mob {
        top: auto;
        #{$rdirection}: 0;
        #{$ldirection}: auto;
        position: relative;
        text-align: #{$rdirection};
        margin-top: 0.6%;
        margin-bottom: 2.4%;
        li {
          display: inline-block;
          width: 24px;
          height: 24px;
          margin: 0 3px;
          a {
            line-height: 1;
          }
        }
      }
    }
    .discover-article__social--pinterest {
      display: none;
      position: absolute;
      bottom: 6.8%;
      max-width: 100%;
      #{$rdirection}: 10px;
      @media screen and (max-width: $max-mob-width) {
        display: block;
      }
      a {
        display: inline-block;
        width: 40px;
        height: 40px;
        img {
          max-width: 100%;
          border: 0;
        }
      }
    }
  }
  .social-pin-end {
    position: absolute;
    margin-top: -150px;
  }
  &__tag {
    text-align: center;
    width: 100%;
    margin: 0 auto;
    font-size: 12px;
    letter-spacing: 0.14em;
    font-family: $akzidenz;
    text-transform: uppercase;
    padding-bottom: 3.2%;
    font-weight: 700;
    @media screen and (max-width: $max-mob-width) {
      font-size: 2.5vw;
      letter-spacing: 0.18em;
      padding-bottom: 6.8%;
    }
    .discover-article__subtag {
      font-weight: 400;
    }
  }
  &__primarytag {
    &::after {
      content: ': ';
    }
  }
  h1 {
    font-size: 36px;
    letter-spacing: 0.023em;
    width: 100%;
    text-align: center;
    font-family: $optima-book;
    color: $color-navy;
    padding-bottom: 1.2%;
    @media screen and (max-width: $max-mob-width) {
      font-size: 6.25vw;
      letter-spacing: 0.02em;
      padding-bottom: 2.8%;
    }
    a {
      cursor: pointer;
    }
  }
  &__teaser {
    font-size: 22px;
    letter-spacing: 0;
    width: 100%;
    text-align: center;
    font-family: $optima-book;
    color: $color-navy;
    margin: 0 auto;
    padding-bottom: 2%;
    @media screen and (max-width: $max-mob-width) {
      font-size: 3.75vw;
      letter-spacing: 0.018em;
      line-height: 1.46;
      padding-bottom: 4.5%;
    }
  }
  &__drop-cap {
    padding: 11px 0 0 0;
    float: #{$ldirection};
    font-size: 58px;
    font-family: $optimalight;
    line-height: 0.4;
    color: $color-navy;
    @media screen and (max-width: $max-mob-width) {
      font-size: 10.3125vw;
      padding: 2.65625vw 0 0 0;
    }
    html[dir='rtl'] & {
      float: #{$rdirection};
    }
  }
  .basic-textarea-v1 {
    max-width: 64.686%;
    margin: 0 auto;
    @media screen and (max-width: $max-mob-width) {
      max-width: 84.688%;
    }
    p {
      letter-spacing: 0.05em;
      line-height: 1.5;
      margin-top: 0;
      margin-bottom: 3%;
      @media screen and (max-width: $max-mob-width) {
        font-size: 3.125vw;
        letter-spacing: 0.018em;
      }
    }
    strong {
      letter-spacing: 0.057em;
    }
    a {
      font-weight: bold;
      text-decoration: underline;
    }
  }
  blockquote {
    font-size: 22px;
    font-family: $optima-book;
    text-align: center;
    color: $color-navy;
    margin: 2.5% auto;
    line-height: 1.2;
    letter-spacing: 0;
    @media screen and (max-width: $max-mob-width) {
      font-size: 3.75vw;
      line-height: 1.46;
      letter-spacing: 0.018em;
      margin: 5.5% auto;
    }
  }
  .basic-img__wrapper {
    img {
      margin-bottom: 2.3%;
      max-width: 100%;
      @media screen and (max-width: $max-mob-width) {
        max-width: 58.28125vw;
      }
    }
    &:hover {
      .discover-article__social--pinterest {
        display: block;
      }
    }
  }
  &__video--yt,
  &__video--content {
    width: 100%;
    position: relative;
    overflow: hidden;
    margin: 4.7% 0;
    .discover-article__video--img {
      width: 100%;
      img {
        width: 100%;
      }
    }
  }
  .ytPlayer__icon,
  .video_content_v1 .video_content_cta_outer {
    margin-#{$ldirection}: -2.2%;
    @media screen and (max-width: $max-mob-width) {
      margin-#{$ldirection}: -4.3%;
    }
  }
}

.device-pc {
  .discover-more {
    padding-bottom: 100px;
  }
  .discover-more__header {
    @include headline--secondary;
    padding: 0 0 65px;
    text-align: center;
    #{$renutriv} & {
      font-family: $bb-roman;
      text-transform: uppercase;
      letter-spacing: normal;
      font-size: 35px;
      letter-spacing: 0.03em;
      font-weight: lighter;
    }
  }
  .discover-more__products {
    @include pie-clearfix;
    @include swap_direction(margin, 0 auto);
    width: 820px;
    @media #{$medium-only} {
      width: 100%;
    }
  }
  .discover_more__product {
    position: relative;
    float: $ldirection;
    width: 246px;
    text-align: center;
    padding-bottom: 43px;
    &:nth-child(2) {
      @include swap_direction(margin, 0 41px);
    }
    @media #{$medium-only} {
      margin-#{$ldirection}: 5px;
      &:nth-child(2) {
        @include swap_direction(margin, 0 5px 0 10px);
      }
    }
  }
  .discover_more__product-url {
    display: block;
  }
  .discover_more__product-image-container {
    position: relative;
    display: block;
    width: 246px;
    height: 350px;
    background: $color-light-gray;
    overflow: hidden;
  }
  .discover_more__product-image {
    position: absolute;
    display: block;
    top: 0;
    #{$ldirection}: -5px;
    width: 256px;
    height: 352px;
  }
  .discover_more__product-header {
    @include headline--quinary;
    margin-top: 40px;
    #{$renutriv} & {
      font-family: $bb-roman;
      text-transform: uppercase;
    }
  }
  .discover_more__product-sub-header {
    @include headline--senary;
    margin-top: 3px;
    #{$renutriv} & {
      @include renutriv-copy;
    }
  }
  .discover_more__product-price {
    font-weight: bold;
    margin-top: 10px;
  }
  .discover_more__product-reviews {
    margin-top: 40px;
  }
  .discover_more__product-quickshop {
    margin-top: 16px;
  }
  .discover_more__reviews-image {
    margin-bottom: 4px;
  }
  .discover_more__product-shopnow {
    position: absolute;
    bottom: 0;
    display: block;
    width: 130px;
    text-align: center;
    margin-#{$ldirection}: 58px;
    #{$renutriv} & {
      color: $color-gold--renutriv;
      text-transform: uppercase;
      font-family: $roboto-condensed;
    }
  }
  .discover-more--estee-edit {
    font-family: $ffmarkweb;
    text-transform: uppercase;
    .discover_more__product-header-container {
      position: relative;
      min-height: 86px;
    }
    .discover_more__product-reviews {
      font-size: 11px;
      font-family: $akzidenz;
      margin-top: 0;
      span {
        padding-#{$ldirection}: 6px;
      }
    }
    .discover-more__header {
      font-family: $devinne-italic;
      font-size: 60px;
      line-height: 70px;
      padding-bottom: 4px;
      text-transform: none;
    }
    .discover_more__misc-flag {
      font-size: 11px;
      height: 11px;
      font-family: $ffmarkweb;
      margin-top: 10px;
      letter-spacing: 0;
    }
    .discover_more__product-header {
      font-family: $ffmarkweb-medium;
      font-size: 14px;
      height: 14px;
      font-weight: normal;
      letter-spacing: 0;
      margin-top: 4px;
    }
    .discover_more__product-sub-header {
      font-family: $ffmarkweb;
      font-size: 14px;
      height: 14px;
      font-weight: normal;
      letter-spacing: 0;
      margin-top: 0;
    }
    .discover_more__product-price {
      font-family: $ffmarkweb;
      font-weight: normal;
      letter-spacing: 0;
      font-size: 14px;
      margin-top: 14px;
      position: absolute;
      bottom: 0;
      text-align: center;
      width: 100%;
    }
    .discover_more__buttons {
      @include clearfix;
      position: absolute;
      bottom: 0;
      text-align: $ldirection;
      width: 164px;
      margin-#{$ldirection}: 41px;
      &.discover_more__buttons--center {
        margin-#{$ldirection}: 68px;
      }
    }
    .discover_more__product-quickshop {
      float: $ldirection;
      width: 120px;
      font-family: $ffmarkweb-medium;
      font-size: 12px;
      font-weight: normal;
      letter-spacing: 0;
      padding: 0 5px;
      text-align: center;
    }
    .discover_more__video {
      @include swap_direction(margin, 16px 0 0 10px);
      background-size: auto auto;
      background-position: 0 -11824px;
      display: block;
      float: $ldirection;
      width: 34px;
      height: 33px;
      border: 1px solid $color-light-gray;
    }
  }
  .discover_more__product_brief__desc1 {
    padding-#{$ldirection}: 0;
    padding-#{$rdirection}: 0;
    font-size: 11px;
    margin-bottom: 7px;
    margin-top: 52px;
  }
}

.spp-discover-more-section-mobile {
  width: 100%;
  text-align: center;
  clear: both;
  display: inline-block;
  color: $color-navy;
  margin-bottom: 40px;
  h4.spp-product__section-header {
    text-align: center;
    font-family: $akzidenz;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.1em;
    color: $color-navy;
    text-transform: uppercase;
    @include swap_direction(margin, 10px 0 20px);
  }
  .discover-more__mobile-slider {
    margin-#{$ldirection}: 25px;
    margin-#{$rdirection}: 25px;
    display: inline-block;
  }
  .discover_more__product {
    width: 45%;
    margin-#{$ldirection}: 5%;
    margin-#{$rdirection}: 0;
    float: $ldirection;
    padding-bottom: 60px;
    position: relative;
    &:first-child {
      margin-#{$rdirection}: 5%;
      margin-#{$ldirection}: 0;
    }
    .discover_more__product-header {
      font-size: 22px;
      letter-spacing: 0;
      color: $color-navy;
      font-family: $optimalight;
      font-weight: bold;
      margin-bottom: 5px;
    }
    .discover_more__product-sub-header {
      font-size: 16px;
      line-height: 18px;
      letter-spacing: 0;
      color: $color-navy;
      font-family: $optimalight;
    }
    .discover_more__product-price {
      font-family: $akzidenz;
      font-size: 12px;
      font-weight: bold;
      #{$estee_edit} & {
        margin-top: 7px;
        line-height: normal;
        font-family: $ffmarkweb-medium;
        letter-spacing: 0;
        font-size: 15px;
      }
    }
    .discover_more__product-image-container {
      margin-bottom: 15px;
      img {
        width: 100%;
        height: auto;
      }
    }
    .discover_more__product-shopnow {
      @include swap_direction(padding, 0);
      @include swap_direction(margin, 10px 0);
      width: 100%;
      height: 46px;
      line-height: 46px;
      position: absolute;
      bottom: 0;
      #{$ldirection}: 0;
    }
  }
  .product-brief__headers__container {
    #{$estee_edit} & {
      min-height: 88px;
      line-height: normal;
    }
  }
  .product_brief__misc-flag {
    #{$estee_edit} & {
      font-family: $ffmarkweb;
      margin-bottom: 3px;
      letter-spacing: 0;
      font-size: 11px;
      text-transform: uppercase;
      font-weight: normal;
      letter-spacing: 0;
    }
  }
  .discover_more__product-header {
    #{$estee_edit} & {
      font-family: $ffmarkweb-medium;
      text-transform: uppercase;
      font-size: 15px;
      color: $color-black;
      letter-spacing: 0;
      font-weight: normal;
      line-height: normal;
      margin: 0;
      min-height: 30px;
    }
  }
  .discover_more__product-sub-header {
    #{$estee_edit} & {
      font-family: $ffmarkweb-medium;
      text-transform: uppercase;
      font-size: 11px;
      color: $color-black;
      letter-spacing: 0;
      font-weight: normal;
      line-height: 11px;
    }
  }
  .discover_more__price_review__container {
    #{$estee_edit} & {
      min-height: 35px;
      margin-top: 7px;
    }
  }
  .discover_more__product-reviews {
    #{$estee_edit} & {
      line-height: normal;
    }
  }
}
// Estee Edit video
.colorbox__estee-edit {
  .device-mobile & {
    &#colorbox {
      background: rgba(0, 18, 201, 0.75); // estee bright blue with opacity
    }
    background: none;
    #cboxContent {
      @include opacity(1);
      background: none;
    }
    .cboxIframe {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      margin: 0 auto;
      @media #{$small-only} {
        @media #{$portrait} {
          width: 100%;
          height: 50%;
        }
      }
      @media #{$iphone6-landscape} {
        width: 100%;
        height: 100%;
      }
    }
  }
}
